// import logo from './images/music_header.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-background">
      
      <h1 className='App-header'>Heksenketel</h1>
        
        
        
      </header>
    </div>
  );
}

export default App;
